/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format("woff2");
}

@font-face {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/ArialMT.woff2") format("woff2");
}

.material-icons {
  font-family: "Material Icons", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  min-width: 1100px;
}

.app-word-cloud {
  height: 500px;
}

.app-word-cloud > div {
  height: 100%;
}

.splitter-layout {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.splitter-layout .layout-pane {
  position: relative;
  flex: 0 0 auto;
  overflow: auto;
}

.splitter-layout .layout-pane.layout-pane-primary {
  flex: 1 1 auto;
}

.splitter-layout > .layout-splitter {
  flex: 0 0 auto;
  width: 8px;
  height: 100%;
  cursor: col-resize;
  background-color: #9fa8da;
  border-radius: 3px;
  margin: 0 2px;
}

.splitter-layout .layout-splitter:hover {
  background-color: #7986cb;
}

.splitter-layout.layout-changing {
  cursor: col-resize;
}

.splitter-layout.layout-changing > .layout-splitter {
  background-color: #7986cb;
}

.splitter-layout.splitter-layout-vertical {
  flex-direction: column;
}

.splitter-layout.splitter-layout-vertical.layout-changing {
  cursor: row-resize;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter {
  width: 100%;
  height: 4px;
  cursor: row-resize;
}

.ag-overlay-no-rows-center {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
}

.twitter-picker {
  background-color: #f2f2f2 !important;
}

.ag-row:hover {
  background-color: #ecf0f1 !important;
}

.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-row-selected:hover {
  background-color: #b7e4ff !important;
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@media (min-width: 1100px) {
  body {
    font-size: 12px !important;
  }
}

.ag-watermark {
  display: none !important;
}

.app_aside {
  scrollbar-width: none;
}

.app_aside::-webkit-scrollbar {
  width: 0;
}

.ripple {
  background-color: red !important;
}

.date-picker {
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
}

.diff {
  position: relative;
}

.diff::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: #ff9800;
  z-index: 1000;
}

.react-split .full-content {
  overflow: auto !important;
}
.react-split > .split-container > .primary {
  overflow: auto !important;
}
.react-split > .split-container {
  overflow: auto !important;
}
.react-split {
  overflow: auto !important;
}

.systemError {
  background-color: #ef5350;
  color: #fff;
  padding: 5px 10px;
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.systemErrorShortText {
  font-size: 14px;
  margin-left: 10px;
}

.systemErrorText {
  display: none;
  background-color: #ef5350;
  padding: 10px;
  font-size: 14px;
  position: absolute;
  top: -15px;
  left: 0;
  z-index: 2;
  width: 900px;
  border-radius: 5px;
  white-space: break-spaces;
}

.systemError:hover .systemErrorText {
  display: block;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #666 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Для аудио плеера */
.full-width-element {
  position: "absolute";
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: "10px";
  box-sizing: "border-box";
  overflow: "hidden";
  background-color: "blue";
}

/* для кастомной анимации загрузки вейвформы */
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cube {
  width: 10px;
  height: 10px;
  /* background-color: #4db6ac; */
  margin: 0 10px;
  border-radius: 3px;
  animation: bounce 0.3s infinite alternate;
}

.cube:nth-child(2) {
  animation-delay: 0.1s;
}

.cube:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.75);
  }
}

.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.centeredText {
  display: "flex";
  align-items: "center";
}

.ag-cell {
  display: flex;
  align-items: center;
}

.ag-header-container {
  white-space: wrap;
}

.ag-header {
  white-space: wrap;
}

.ag-header-cell-text {
  white-space: wrap;
}

.MuiAppBar-positionFixed {
  position: static !important;
}

/* .ag-body-horizontal-scroll-viewport {
  overflow:auto
} */

@media (max-width: 1350px) {
  .responsive-button {
    min-width: 50px !important;
  }
}
